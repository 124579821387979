@font-face {
    font-family: 'Delphia';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    font-stretch: normal;
    src: local(''),
         url('../../fonts/Delphia-Regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../../fonts/Delphia-Regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
    font-family: 'Delphia';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    font-stretch: normal;
    src: local(''),
         url('../../fonts/Delphia-Medium.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../../fonts/Delphia-Medium.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
    font-family: 'Delphia';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    font-stretch: expanded;
    src: local(''),
         url('../../fonts/Delphia-RegularWide.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../../fonts/Delphia-RegularWide.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
    font-family: 'Delphia';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    font-stretch: expanded;
    src: local(''),
         url('../../fonts/Delphia-MediumWide.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../../fonts/Delphia-MediumWide.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
    font-family: 'Delphia';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    font-stretch: expanded;
    src: local(''),
         url('../../fonts/Delphia-BoldWide.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../../fonts/Delphia-BoldWide.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
    font-family: 'Ruder Plakat';
    font-style: normal;
    font-weight: 900;
    font-display: block;
    font-stretch: normal;
    src: local(''),
         url('../../fonts/RuderPlakatLLVIPWeb.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../../fonts/RuderPlakatLLVIPWeb.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
