/**
 * Basic typography defintions
 */
html { 
    font-family: var(--font-delphia);
    font-stretch: expanded;
    font-weight: 500;
    font-size: 20px;

    @include larger() {
        font-size: 30px;
    }
}

a {
    color: inherit;
    text-decoration: underline;
}

b, strong {
    font-weight: 700;
}

i, em {
    font-style: normal;
}

h1, h2, h3, h4, h5, h6 {
    a {
        &, &:hover {
            color: inherit;
            text-decoration: inherit;
        }
    }
}

h1 {
    @include h1();
}

h2 {
    @include h2();
}

h3 {
    @include h3();
}

h4 {
    @include h4();
}

h5 {
    @include h5();
}

h6 {
    @include h6();
}

ul,
ol,
p {
    @include typo();
}

ul,
ol {
    margin: 1em 0;
}

/* headline styles */
h1.headline--h1,
h2.headline--h1,
h3.headline--h1,
.headline--h1 h1,
.headline--h1 h2,
.headline--h1 h3 {
    @include h1();
}

h1.headline--h2,
h2.headline--h2,
h3.headline--h2,
.headline--h2 h1,
.headline--h2 h2,
.headline--h2 h3 {
    @include h2();
}

h1.headline--h3,
h2.headline--h3,
h3.headline--h3,
.headline--h3 h1,
.headline--h3 h2,
.headline--h3 h3 {
    @include h3();
}

h1.headline--h4,
h2.headline--h4,
h3.headline--h4,
.headline--h4 h1,
.headline--h4 h2,
.headline--h4 h3 {
    @include h4();
}

/* margins */
@mixin margin-generator($type, $margin) {
    @if $type == '' {
        .margin--#{$margin} {
            @include margin-type('top', $margin, true);
            @include margin-type('bottom', $margin, true);
        }
    } @else {
        .margin--#{$type}--#{$margin} {
            @include margin-type($type, $margin, true);
        }
    }
}

@mixin margin($margin) {
    @include margin-generator('', $margin);
    @include margin-generator('top', $margin);
    @include margin-generator('bottom', $margin);
}

@include margin(0);
@include margin(1);
@include margin(2);
@include margin(3);
@include margin(4);
@include margin(5);
@include margin(6);
@include margin(7);
@include margin(8);

.image--center figure img {
    margin: auto;
}

.image--left figure img {
    margin-left: 0;
    margin-right: auto;
}

.image--right figure img {
    margin-right: 0;
    margin-left: auto;
}

.text--center {
    text-align: center;
}

.text--left {
    text-align: left;
}

.text--right {
    text-align: right;
}

.text--medium {
    font-size: 30px;

    @include larger() {
        font-size: 50px;
    }
}

.scale-text {
    display: block;
    text-align: center;

    &.has-umlaut {
        margin-top: 0.12em;
    }
}

.hght--low {
    font-variation-settings: "HGHT" 100;
    line-height: 0.82;
}

.hght--reg {
    font-variation-settings: "HGHT" 250;
    line-height: 0.9;
}

.hght--high {
    font-variation-settings: "HGHT" 500;
    line-height: 1.04;
}

.hght--tall {
    font-variation-settings: "HGHT" 900;
    line-height: 1.22;
}
