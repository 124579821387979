:root {
    --container-padding-top: 64px;
}

body.page--index {
    --container-padding-top: 96px;
}

@include desktop('max') {
    body.page--event {
        --container-padding-top: 0;
    }
}

@include menu() {
    :root {
        --container-padding-top: 96px;
    }

    body.page--index {
        --container-padding-top: 114.6px;
    }
}

@include larger() {
    :root {
        --container-padding-top: 135px;
    }

    body.page--index {
        --container-padding-top: 194.5px;
    }
}

#container {
    max-width: var(--max-width);
    margin-left: auto;
    margin-right: auto;
    position: relative;
    padding-top: var(--container-padding-top);
    z-index: 4;
    flex-grow: 1;
}
