.mod_pageimage {
    position: fixed;
    left: 0;
    right: 0;
    max-width: 1920px;
    margin: auto;
    top: 0;
    z-index: 1;

    figure img {
        width: 100%;
    }
}

.mod_pageimage.pageimage--bottom {
    position: absolute;
    z-index: 2;
}
