[class*="ce_"] {
    &.width--full {
        margin-left: calc(var(--outer-padding) * -1);
        margin-right: calc(var(--outer-padding) * -1);
    }

    &.width--950 {
        max-width: 570px;
        margin-left: auto;
        margin-right: auto;

        .event-full__details & {
            margin-left: 0;
        }

        @include larger() {
            max-width: 950px;
        }
    }
}
