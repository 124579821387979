.newslist {
    &__items {
        .empty {
            margin: 1em 0;
            font-size: 0.8em;
        }
    }

    &--press {
        .newslist {
            &__items {
                display: grid;
                row-gap: 40px;

                .newsitem {
                    min-width: 0;
                    max-width: none;
                }
        
                @include desktop() {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    column-gap: 10px;
                }
        
                @include larger() {
                    grid-template-columns: 1fr 1fr 1fr 1fr;
                    column-gap: 22px;
                }
            }
        }
    }
}
