.ce_gallery {
    margin: 0 calc(var(--outer-padding) * -1);

    body.page--event & {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    @include desktop() {
        margin: 0;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: grid;
        gap: 0.486666em;
        grid-template-columns: 1fr;

        @include desktop() {
            @for $i from 1 through 12 {
                &.cols_#{$i} {
                    grid-template-columns: repeat(#{$i}, 1fr);
                }
            }

            body.page--event & {
                grid-template-columns: 1fr !important;
                gap: 34px;
            }
        }

        @include larger() {
            body.page--event & {
                grid-template-columns: 1fr 1fr !important;
            }
        }
    }
}
