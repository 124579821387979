#footer {
    position: relative;
    z-index: 3;
    background-color: var(--primary-color);
    padding: 0 var(--outer-padding);
    font-family: var(--font-delphia);
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: -0.02em;
    padding-top: 1px;
    padding-bottom: 1px;

    @include tablet() {
        font-size: 10px;
    }

    @include larger() {
        font-size: 15px;
    }

    ul, ol, p {
        line-height: inherit;
    }

    & > .inside {
        max-width: 1710px;
        margin-top: 103px;
        margin-bottom: 79px;
        margin-left: auto;
        margin-right: auto;
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 33px;
        row-gap: 48px;

        @include tablet() {
            grid-template-columns: 1fr 1fr 1fr 999fr;
            column-gap: vwm(45px);
        }

        @include larger() {
            column-gap: vwmax(64px);
        }

        .footer {
            &__column {
                a {
                    text-decoration: none;
                }

                &--newsletter,
                &--social {
                    grid-column: 1 / -1;
                }

                h2 {
                    margin-top: 0;
                    font-stretch: expanded;
                    font-size: 18px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    letter-spacing: -0.02em;
                    margin-bottom: 0.6em;
                    text-transform: uppercase;

                    @include tablet() {
                        font-size: 14px;
                    }

                    @include larger() {
                        font-size: 20px;
                    }
                }

                @include tablet() {
                    &--contact {
                        padding-right: vwm(60px);
                    }

                    &--newsletter {
                        padding-right: vwm(50px);
                        min-width: 120px;
                    }

                    &--newsletter,
                    &--social {
                        grid-column: span 1;
                    }
                }

                @include larger() {
                    &--contact {
                        padding-right: vwmax(10px);
                    }

                    &--newsletter {
                        padding-right: vwmax(55px);
                        min-width: 202px;
                    }
                }
            }
        }
    }
}
