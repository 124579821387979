body.layout--green-on-black {
    --text-color: var(--green);
    --primary-color: var(--black);

    #logo {
        filter: brightness(0) saturate(100%) invert(90%) sepia(5%) saturate(3859%) hue-rotate(35deg) brightness(103%) contrast(112%);
    }

    iframe[src^="cookiebar"] {
        background-color: white;
    }
}

body.layout--black-on-green {
    --primary-color: var(--green);
}
