.ce_image {
    margin: 1em 0;

    @include tablet('max') {
        margin-left: calc(var(--outer-padding) * -1);
        margin-right: calc(var(--outer-padding) * -1);
    }

    &.width--full {
        margin-top: em(50px);
        margin-bottom: em(50px);

        @include tablet() {
            margin-left: calc(var(--outer-padding) * -1);
            margin-right: calc(var(--outer-padding) * -1);
        }
    }
}
