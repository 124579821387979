#wrapper {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    min-height: 100%;

    & > * {
        width: 100%;
        box-sizing: border-box;
    }
}
