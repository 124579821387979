.ce_hyperlink {
    &.hyperlink--back {
        display: flex;
        justify-content: left;
        font-family: var(--font-ruder);
        font-weight: 900;
        font-size: 21px;
        text-transform: uppercase;
        letter-spacing: 0.07em;
        z-index: 8;
        position: relative;

        a {
            display: flex;
            align-items: center;
            text-decoration: none;

            &:before {
                @include msprite('../../images/arrow-left-week.svg');
                width: 9.648px;
                height: 9.783px;
                content: '';
                display: block;
                background-color: var(--text-color);
                margin-right: 6px;
            }
        }

        @include larger() {
            position: absolute;
            top: 0.275em;
            left: -5em;
        }
    }
}
