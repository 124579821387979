.eventlist--featured {
    .swiper {
        --swiper-pagination-bottom: -14px;
        overflow-y: visible;

        @include tablet() {
            --swiper-pagination-bottom: 7px;
        }
    }

    @include tablet() {
        margin-left: calc(var(--outer-padding) * -1);
        margin-right: calc(var(--outer-padding) * -1);
    }

    @include larger() {
        margin-right: 0;
    }
}

.eventlist {
    &__items {
        .empty {
            margin: 1em 0;
            font-size: 0.8em;
        }
    }
}

.eventlist--upcoming {
    margin-top: vw(220px);
    margin-bottom: vw(220px);
    
    @include tablet() {
        margin-top: vwm(340px);
        margin-bottom: vwm(340px);
    }

    @include larger() {
        margin-top: vwmax(525px);
        margin-bottom: vwmax(525px);
    }

    .eventlist {
        &__items {
            display: grid;
            row-gap: 48px;

            .event-teaser {
                min-width: 0;
                max-width: none;
                min-height: 0;
                max-height: none;
                align-self: flex-start;
            }

            @include tablet() {
                grid-template-columns: 1fr 1fr;
                column-gap: vwm(20px);
                row-gap: vwm(30px);
            }

            @include larger() {
                grid-template-columns: 1fr 1fr 1fr;
                column-gap: vwmax(20px);
                row-gap: vwmax(30px);
            }
        }
    }
}

.eventlist--week,
.eventlist--spielplan {
    .eventlist {
        &__headline {
            font-family: var(--font-ruder);
            font-weight: 900;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.1;
            letter-spacing: 0.03em;
            text-transform: uppercase;
            font-variation-settings: "HGHT" 600;
            margin-bottom: 14px;
        }

        &__day {
            padding: 18px 0;
            padding-bottom: 20px;

            &:nth-child(even) {
                @include fwbg(var(--rose));
            }

            @include tablet() {
                display: grid;
                grid-template-columns: 120px 1fr;
                gap: 10px;
            }

            @include larger() {
                grid-template-columns: 159px 1fr;
            }
        }

        &__day-header {
            text-transform: uppercase;
            text-align: right;
            font-family: var(--font-delphia);
            font-size: 13px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: -0.02em;
            margin-top: 0;
            margin-bottom: 0;

            @include tablet() {
                text-align: left;
                font-size: 12px;
                margin-top: 2px;
            }

            @include larger() {
                font-size: 19px; 
            }

            span.date {
                font-family: var(--font-delphia);
                font-size: 30px;
                font-weight: bold;
                font-stretch: expanded;
                font-style: normal;
                line-height: 0.83;
                letter-spacing: -0.02em;
                display: inline-block;
                margin-left: 0.15em;

                @include tablet() {
                    margin-left: 0;
                    display: block;
                    line-height: 1;
                }

                @include larger() {
                    font-size: 40px;
                    line-height: 0.78;
                }
            }
        }

        &__all {
            @include fwbg(var(--primary-color));
            display: flex;
            justify-content: flex-end;
            padding-top: 29px;
            padding-bottom: 67px;

            &-link {
                @include button();
            }
        }

        &__day:nth-child(even) + .eventlist__all {
            @include fwbg(var(--rose));
        }
    }
}

.eventlist--week {
    .eventlist {
        &__headline {
            margin-top: vw(30px);
            margin-bottom: 0;

            @include tablet('max') {
                font-size: vw(67px) !important;
                font-variation-settings: "HGHT" 500;
                word-break: normal !important;
                text-align: center;

                span {
                    display: block;
                }
    
                span:first-child {
                    font-size: vw(75px);
                }
            }

            @include tablet() {
                margin-top: vwm(30px);
            }

            @include larger() {
                margin-top: vwmax(30px);
                margin-left: calc(205px - var(--outer-padding));
                margin-right: calc(205px - var(--outer-padding));
                margin-bottom: 6px;
            }
        }

        &__week-nav-container {
            position: relative;

            @include desktop() {
                padding-bottom: 14px;
            }

            @include breakpoint(1730px) {
                padding-bottom: 0;
            }
        }

        &__week-nav {
            font-family: var(--font-ruder);
            font-size: 21px;
            font-weight: 900;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: 0.07em;
            display: flex;
            justify-content: space-between;
            margin-bottom: 13px;
            text-transform: uppercase;

            @include desktop() {
                font-size: 15px;
                position: absolute;
                right: 0;
                top: 0;
                display: block;
            }

            @include breakpoint(1730px) {
                font-size: 21px;
                right: calc(-151px + var(--outer-padding));
                top: 30px;
            }

            &-link {
                text-decoration: none;
                display: flex;
                gap: 6px;
                align-items: center;

                &:before {
                    @include msprite('../../images/arrow-left-week.svg');
                    width: 9.648px;
                    height: 9.783px;
                    content: '';
                    display: block;
                    background-color: var(--text-color);
                }

                &--next {
                    &:before {
                        mask-image: url('../../images/arrow-right-week.svg');
                        order: 99;

                        @include tablet() {
                            order: -1;
                        }
                    }
                }
            }

            &-item--next {
                margin-left: auto;
            }
        }

        &__items {
            @include desktop() {
                margin-right: calc(141px - var(--outer-padding));
            }

            @include larger() {
                max-width: 1258px;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}

.eventlist--program {
    margin-top: vwmax(50px);

    .empty {
        grid-column: 1 / -1;
    }

    .eventlist__headline {
        display: none;
    }

    @include tablet() {
        max-width: 996px;
        margin-left: auto;
        margin-right: auto;

        .eventlist__items {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 16px;
            row-gap: 20px;
        }
    }

    @include larger() {
        max-width: none;

        .eventlist__items {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
}

.eventlist--more {
    .eventlist__items {
        display: grid;
        row-gap: 40px;

        @include desktop() {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            column-gap: 10px;

            & > *:nth-child(4) {
                display: none;
            }
        }

        @include larger() {
            grid-template-columns: 1fr 1fr 1fr 1fr;
            column-gap: 22px;

            & > *:nth-child(4) {
                display: block;
            }
        }
    }
}

.eventlist--spielplan {
    .eventlist {
        &__headline {
            font-size: rem(33px) !important;
            font-family: var(--font-delphia);
            font-stretch: expanded;
            letter-spacing: -0.02em;
            line-height: 0.58;
            font-weight: 700;
            text-transform: uppercase;
            margin-bottom: 0;
            margin-top: 0.25em;
        }

        &__items {
            @include larger() {
                max-width: 1500px;
                margin-left: auto;
                margin-right: auto;
            }
        }

        &__day {
            @media only screen and (min-width: $tablet) and (max-width: ($larger - 0.02px)) {
                grid-template-columns: 1fr;
                gap: 0;
            }
        }
    }
}

@media only screen and (min-width: $tablet) and (max-width: ($larger - 0.02px)) {
    :root {
        --outer-padding: 21px;
    }
}

.eventlist--screen {
    @include tablet() {
        margin: 0 calc(var(--outer-padding) * -1);
    }

    @include larger() {
        margin-right: 0;
    }

    &.swiper, &.swiper-container {
        overflow: visible;
    }
}
