:root {
    --app-height: 100vh;
    --font-delphia: 'Delphia', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    --font-ruder: 'Ruder Plakat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    --max-width: #{$max-width};
    --yellow: #ffe947;
    --black: black;
    --outer-padding: 20px;
    --primary-color: var(--yellow);
    --rose: #ff6565;
    --text-size: #{$text-size-mobile};
    --default-transition-duration: 0.3s;
    --td: var(--default-transition-duration);
    --text-color: var(--black);
    --green: #C0FF6A;
}

@include tablet() {
    :root {
        --outer-padding: 74px;
    }
}

@include larger() {
    :root {
        --outer-padding: 105px;
        --text-size: #{$text-size-desktop};
    }
}

/**
 * Basic styling, basic font size etc.
 */
html {
    scroll-behavior: smooth;
}

body, html {
    height: 100%;
}

body { 
    overflow: hidden;
    overflow-y: scroll;
    background-color: var(--primary-color);
    color: var(--text-color);
}

figure {
    display: block;
    max-width: 100%;
    margin: 0;
    box-sizing: border-box;
}

main {
    display: block;
}

img {
    border: 0;
}

video {
    max-width: 100%;
    height: auto;
}

figure img {
    width: auto;
    height: auto;
    max-width: 100%;
    display: block;
    margin: 0;
}

figure figcaption {
    display: block;
    margin: 2px 17px;
    font-family: var(--font-delphia);
    font-size: 8px;
    font-weight: 500;
    font-stretch: expanded;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: -0.02em;

    .credits {
        font-stretch: normal;
        font-weight: normal;
    }

    @include desktop() {
        margin: 2.9px 0;
        font-size: 12px;
    }
}

iframe {
    border:0;
}

.invisible {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.wow {
    visibility: hidden;
}

.inside {
    position:relative;
}

.ce_player video {
    width: 100%;
    height: auto;
}

/* default 16:9 aspect ratio */
.ce_youtube, .ce_vimeo {
    .video-wrapper,
    .video_container {
        position: relative;
        padding-bottom:56.25%;
        height:0;
    }

    .video-wrapper > *,
    .video_container > :not(.responsive):not(.caption),
    .video_container > .responsive > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
        z-index: 6;
    }
}
