#header {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 5;

    & > .inside {
        &:before {
            content: '';
            display: table;
        }

        @include menu() {
            position: relative;
            max-width: var(--max-width);
            margin-left: auto;
            margin-right: auto;
        }
    }
}
