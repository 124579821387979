.ce_downloads {
    font-size: rem(20px);
    margin: 1em 0;

    & > ul {
        & > li {
            & > a {
                
            }
        }
    }

    &.downloads--grid {
        & > ul {
            display: grid;
            row-gap: 40px;
            list-style: none;
            margin: 0;
            padding: 0;

            .download-element {
                min-width: 0;
                max-width: none;

                figure {
                    margin-bottom: 0.5em;
                }
            }

            @include desktop() {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                column-gap: 10px;
            }

            @include larger() {
                grid-template-columns: 1fr 1fr 1fr 1fr;
                column-gap: 22px;
            }
        }
    }

    &.downloads--list {
        & > ul {
            list-style: none;
            margin: 0;
            padding: 0;

            & > li {
                display: flex;

                & + li {
                    margin-top: 0.4em;
                }

                & > a {
                    &:before {
                        display: inline-block;
                        vertical-align: text-bottom;
                        background-image: var(--download-icon);
                        background-size: auto 20px;
                        background-repeat: no-repeat;
                        background-position: center;
                        width: 20px;
                        height: 20px;
                        content: '';
                        margin-right: 0.2em;
                    }
                }
            }
        }
    }
}
