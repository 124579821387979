.event-link {
    margin: 1em 0;
    display: flex;
    justify-content: center;

    &__link {
        display: flex;
        border: 2px solid var(--text-color);
        padding: 0 rem(14px);
        align-items: center;
        padding-top: rem(6px);
        padding-bottom: rem(5px);
        text-decoration: none;
        transition: color var(--td), background-color var(--td);
        color: var(--text-color);

        &:hover {
            color: var(--primary-color);
            background-color: var(--text-color);
        }
    }

    &__label {
        font-family: var(--font-ruder);
        font-size: rem(21px);
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.71;
        letter-spacing: 0.05em;
        text-align: left;
        text-transform: uppercase;
        line-height: rem(27px);
    }

    &__dates {
        margin-left: rem(50px);
        font-family: var(--font-delphia);
        font-size: rem(15px);
        font-weight: bold;
        font-stretch: expanded;
        font-style: normal;
        line-height: 1.27;
        letter-spacing: -0.02em;
        text-align: center;
        text-transform: uppercase;
    }

    &__day {
        font-size: rem(11px);
        font-weight: normal;
    }

    &__clock {
        font-size: rem(11px);
        font-weight: bold;
    }
}
