.event-full {
    $root: &;
    background-color: var(--primary-color);

    @include desktop() {
        margin-left: calc(var(--outer-padding) * -1);
        margin-right: calc(var(--outer-padding) * -1);
    }

    &__top {
        @include desktop() {
            position: relative;
            display: grid;
            grid-template-columns: 1fr vwm(759px);
        }

        @include larger() {
            grid-template-columns: 1fr vwmax(1377px);
        }
    }

    &__image {
        max-width: none;
        margin-left: calc(var(--outer-padding) * -1);
        margin-right: calc(var(--outer-padding) * -1);

        img {
            width: 100%;
        }

        @include desktop() {
            margin: 0;
            max-width: 100%;
            order: -2;
        }
    }

    &__intro-wrapper {
        min-width: 0;
        position: relative;

        @include desktop() {
            order: -3;
        }
    }

    &__intro {
        padding: 0 calc(26.3px - var(--outer-padding));
        padding-top: 31.8px;
        position: relative;

        @include desktop() {
            margin-left: vwm(83px);
            margin-right: vwm(22px);
            padding: 0;
            padding-top: vwm(3px);
            position: absolute;

            &.sticky {
                position: fixed;
                top: 8px;
                z-index: 1;
            }
        }

        @include larger() {
            margin-left: vwmax(105px);
            margin-right: vwmax(22px);
            padding: 0;
        }
    }

    &__min-age {
        width: 49.5px;
        height: 49.5px;
        background-color: var(--primary-color);
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        font-size: 16px;
        font-family: var(--font-ruder);
        font-weight: normal;
        font-stretch: normal;
        line-height: 1.31;
        letter-spacing: 0.1em;
        position: absolute;
        top: 88.6px;
        left: calc(8px - var(--outer-padding));

        @include desktop() {
            font-size: vwm(21px);
            width: vwm(65px);
            height: vwm(65px);
            top: vwm(-32.5px);
            right: vwm(52px);
            left: auto;
        }

        @include larger() {
            font-size: vwmax(21px);
            width: vwmax(65px);
            height: vwmax(65px);
            top: vwmax(-32.5px);
            right: vwm(32.3px);
        }
    }

    &.hide-category .event-full__categories{
        display: none;
    }

    &.hide-age .event-full__min-age {
        display: none;
    }

    &__info {
        font-family: var(--font-ruder);
        font-stretch: normal;
        font-size: 14px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        display: flex;
        justify-content: space-between;
        font-weight: 900;
        gap: 1em;

        @include desktop() {
            font-size: vwm(18px);
        }

        @include larger() {
            font-size: vwmax(18px);
        }
    }

    &__contributor {
        text-align: right;
    }

    &__title {
        font-family: var(--font-ruder);
        font-stretch: normal;
        font-weight: 900;
        letter-spacing: 0.025em;
        text-transform: uppercase;
        margin: 0;
        transition: font-variation-settings var(--td), line-height var(--td);
        font-variation-settings: "HGHT" 100;
        line-height: 0.775;
    }

    &__title {
        span.scale-text {
            transition: font-variation-settings var(--td), line-height var(--td);
            font-variation-settings: "HGHT" 200;
            line-height: 0.83;

            &:first-child {
                font-variation-settings: "HGHT" 700;
                line-height: 1.08;
            }
        }
    }

    &__subtitle {
        font-family: var(--font-ruder);
        font-stretch: normal;
        letter-spacing: 0.025em;
        text-transform: uppercase;
        font-weight: 900;
        line-height: 0.945;
        font-variant-ligatures: none;
        margin: 0;
    }

    &__categories {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        row-gap: 1em;
        column-gap: 0.5em;
        font-family: var(--font-ruder);
        font-stretch: normal;
        font-size: 19px;
        font-weight: 900;
        line-height: 1.5;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        font-variation-settings: "HGHT" 900;

        @include desktop() {
            font-size: vwm(21px);
        }

        @include larger() {
            font-size: vwmax(21px);
        }

        &--c1 {
            justify-content: center;
        }
    }

    &__time {
        font-family: var(--font-delphia);
        font-weight: bold;
        font-stretch: expanded;
        font-style: normal;
        letter-spacing: -0.02em;
        text-transform: uppercase;
        text-align: center;
        margin: 0.032em 0;
        margin-top: 0.2em;
        line-height: 1.0;
        font-size: 27px;

        @include desktop() {
            font-size: vwm(36px);
        }

        @include larger() {
            font-size: vwmax(25px);
        }
    }

    &__teaser {
        @include pr();
        text-align: center;
        font-size: 14.3px;
        font-weight: 500;
        line-height: 1.06;
        font-stretch: normal;
        letter-spacing: 0.02em;
        margin: 0.35em 0;

        @include desktop() {
            font-size: vwm(18px);
        }

        @include larger() {
            font-size: vwmax(18px);
        }
    }

    &__dates {
        list-style: none;
        padding: 0;
        margin: 0;
        margin-top: 5px;

        @include desktop() {
            margin-top: vwm(13px);
        }

        @include larger() {
            margin-top: vwmax(13px);
        }

        &-item {
            &-link {
                display: flex;
                gap: 0.5em;
                justify-content: space-between;
                align-items: center;
                border: 2px solid var(--text-color);
                line-height: 25px;
                padding: 6.5px 16px 6.5px 11px;
                text-decoration: none;
                text-transform: uppercase;
                transition: background-color var(--td), color var(--td);
                
                &:hover,
                &--unavailable {
                    background-color: var(--text-color);
                    color: var(--primary-color);
                }

                @include desktop() {
                    line-height: vwm(27px);
                    padding: vwm(5.5px) vwm(16px) vwm(5.5px) vwm(11px);
                }

                @include larger() {
                    line-height: vwmax(27px);
                    padding: vwmax(5.5px) vwmax(16px) vwmax(5.5px) vwmax(11px);
                }
            }

            &-label {
                font-family: var(--font-ruder);
                font-weight: 900;
                font-stretch: normal;
                font-size: 19px;
                letter-spacing: 0.1em;

                @include desktop() {
                    font-size: vwm(21px);
                }

                @include larger() {
                    font-size: vwmax(21px);
                }
            }

            &-date {
                font-family: var(--font-delphia);
                font-stretch: expanded;
                font-size: 14px;
                font-weight: bold;
                letter-spacing: -0.02em;
                line-height: 1.065;

                @include desktop() {
                    font-size: vwm(15px);
                }

                @include larger() {
                    font-size: vwmax(15px);
                }
            }

            &-clock,
            &-weekday {
                font-size: 11px;

                @include desktop() {
                    font-size: vwm(11px);
                }

                @include larger() {
                    font-size: vwmax(11px);
                }
            }

            & + & {
                margin-top: 5px;
            }

            &.hide {
                display: none;
            }

            &--group-last {
                margin-bottom: 0.8em;
            }
        }
    }

    &__content {
        position: relative;

        @include desktop() {
            display: grid;
            grid-template-columns: 1fr vwm(759px);
        }

        @include larger() {
            grid-template-columns: 1fr vwmax(1337px);
        }
    }

    &__additional {
        margin-top: 38.4px;

        @include desktop() {
            margin-left: vwm(77.5px);
            margin-top: vwm(546px);
            margin-right: vwm(29px);
        }

        @include larger() {
            margin-left: vwmax(105px);
            margin-top: vwmax(680px);
            margin-right: vwmax(62px);
        }
    }

    &__details {
        @include desktop() {
            margin-right: vwm(52px);

            & > *.width--full {
                margin-left: vwm(-469px);
                max-width: none;

                & ~ .ce_text.text--medium {
                    margin-left: vwm(-279px);
                }

                & ~ .ce_segmented_text {
                    margin-left: vwm(-469px);
                }
            }
        }

        @include larger() {
            margin-right: vwmax(105px);

            & > *.width--full,
            & > .ce_image.width--full {
                margin-left: vwmax(-478px);
                margin-right: 0;

                & ~ .ce_text.text--medium {
                    margin-left: 0;
                }

                & ~ .ce_segmented_text {
                    margin-left: 0;
                }
            }
        }
    }

    &__info-text {
        margin-bottom: 3.3px;

        @include tablet() {
            margin-bottom: 27.7px;
        }

        @include larger() {
            margin-bottom: 32.7px;
        }

        &-headline {
            font-family: var(--font-delphia);
            font-size: 13px;
            font-weight: bold;
            font-stretch: expanded;
            font-style: normal;
            line-height: 1.04;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            margin-top: 0;
            margin-bottom: 0;
        }

        &-text {
            @include pr();
            font-family: var(--font-delphia);
            font-size: 13px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.04;
            letter-spacing: 0.02em;
            margin-left: 16px;

            p {
                line-height: inherit;
            }
        }
    }

    &__credits {
        list-style: none;
        margin: 0;
        padding: 0;
        margin-top: 6px;

        &-row {
            display: flex;
            justify-content: space-between;
        }

        dt {
            font-family: var(--font-delphia);
            font-size: 15px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: -0.02em;
            text-transform: uppercase;
            margin: 0;
            padding: 0;

            @include breakpoint(420px) {
                flex-shrink: 0;
            }

            @include desktop() {
                font-size: 14px;
            }

            @include larger() {
                font-size: 15px;
            }
        }

        dd {
            font-family: var(--font-ruder);
            font-size: 15px;
            font-weight: 900;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.06;
            letter-spacing: 0.07em;
            text-align: right;
            text-transform: uppercase;
            margin: 0;
            padding: 0;
            margin-left: 10px;
        }
    }
}
