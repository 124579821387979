@include menu('max') {
    #offcanvas {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        height: 0;
        overflow-y: auto;
        z-index: 10;
        background-color: var(--primary-color);
        transition: height var(--td);
        box-sizing: border-box;

        &:before {
            content: '';
            display: block;
            height: 36.8px;
        }

        &:after {
            content: '';
            display: block;
            height: 62px;
        }

        body.show-menu & {
            height: var(--app-height);
        }
    }

    body.show-menu {
        overflow-y: hidden;
    }
}

@include menu() {
    #offcanvas {
        opacity: 0;
        position: absolute;
        left: 0;
        right: 0;
        top: -100px;
        max-height: 90px;
        transition: opacity var(--td), transform var(--td), max-height var(--td), background-color var(--td);
        overflow: hidden;
        background-color: transparent;

        body:not(.scroll--down) #header:hover &,
        body.scroll--up #header:hover &,
        body.pull-down-menu #header:hover &,
        body.show-menu & {
            max-height: 500px;
            background-color: var(--primary-color);
        }

        body.scroll--up.scroll--scrolled &,
        body.pull-down-menu &,
        body:not(.page--index):not(.scroll--scrolled) &,
        body.show-menu & {
            opacity: 1;
            transform: translateY(100px);
        }

        & > .inside {
            position: relative;
            max-width: var(--max-width);
            margin-left: auto;
            margin-right: auto;
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
    }
}
