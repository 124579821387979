.general-teaser,
.page-teaser {
    $root: &;
    background-color: var(--primary-color);
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 29px;
    margin-bottom: 58px;

    @include tablet() {
        max-width: vwm(552px);
    }

    @include larger() {
        max-width: vwmax(552px);
    }

    &__content {
        min-width: 0;
        padding: 10px;
        padding-top: 4px;
        order: 1;

        @include tablet() {
            padding-top: vwm(4px);
            padding-right: vwm(14px);
            padding-left: vwm(14px);
            padding-bottom: vwm(15px);
        }

        @include larger() {
            padding-top: vwmax(4px);
            padding-right: vwmax(15px);
            padding-left: vwmax(15px);
            padding-bottom: vwmax(17px);
        }
    }

    &__image {
        .image-wrapper {
            overflow: hidden;
        }

        img {
            transition: transform var(--td);
            width: 100%;
        }

        @include tablet() {
            order: 2;
        }
    }

    &__headline {
        font-family: var(--font-ruder);
        font-stretch: normal;
        font-weight: 900;
        letter-spacing: 0.025em;
        text-transform: uppercase;
        margin: 0;
        transition: font-variation-settings var(--td), line-height var(--td);
        font-variation-settings: "HGHT" 250;
        line-height: 0.9;
    }

    @include tablet() {
        &:hover {
            #{$root} {
                &__headline.scale-text {
                    font-variation-settings: "HGHT" 600;
                    line-height: 1.08;
                }
            }
        }
    }

    &__headline {
        span.scale-text {
            transition: font-variation-settings var(--td), line-height var(--td);
            font-variation-settings: "HGHT" 200;
            line-height: 0.83;

            &:first-child {
                font-variation-settings: "HGHT" 700;
                line-height: 1.08;
            }
        }
    }

    @include tablet() {
        &:hover {
            #{$root} {
                &__headline {
                    span.scale-text {
                        font-variation-settings: "HGHT" 700;
                        line-height: 1.07;

                        &:first-child {
                            font-variation-settings: "HGHT" 200;
                            line-height: 0.82;
                        }
                    }
                }

                &__image {
                    img {
                        transform: scale(1.065);
                    }
                }
            }
        }
    }

    &__text {
        @include pr();
        text-align: center;
        font-size: 14.3px;
        font-weight: 500;
        line-height: 1.06;
        font-stretch: normal;
        letter-spacing: 0.02em;
        margin: 0.35em 0;

        @include tablet() {
            font-size: vwm(18px);
        }

        @include larger() {
            font-size: vwmax(18px);
        }
    }

    &--float-below {
        #{$root} {
            &__image {
                order: -1;
            }

            &__content {
                padding-top: 10px;
                padding-bottom: 4px;
        
                @include tablet() {
                    padding-bottom: vwm(4px);
                    padding-top: vwm(15px);
                }
        
                @include larger() {
                    padding-bottom: vwmax(4px);
                    padding-top: vwmax(17px);
                }
            }
        }
    }
}
