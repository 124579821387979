.menu-button {
    width: 32px; //22.484px;
    height: 32px; //22.484px;
    cursor: pointer;
    border: 0;
    outline: 0;
    padding: 0;
    border-radius: 0;
    background-color: transparent;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20;
    position: absolute;
    top: 17.5px;
    right: 11.242px;

    &__line {
        transition: transform var(--td), opacity var(--td), visibility var(--td);
        transform: translateY(0) rotate(0deg);
        transform-origin: 50% 50%;
        stroke: var(--text-color);

        &--center {
            &--2 {
                visibility: hidden;
            }
        }
    }

    @include menu() {
        //display: none;
    }

    body.show-menu & {
        &__line {
            &--top,
            &--bottom {
                opacity: 0;
            }

            &--top {
                transform: translateY(9px);
            }

            &--center {
                &--1 {
                    transform: rotate(45deg);
                }

                &--2 {
                    visibility: visible;
                    transform: rotate(-45deg);
                }
            }

            &--bottom {
                transform: translateY(-9px);
            }
        }
    }
}
