.instagram {
    margin: 2em auto;
    min-width: 280px;
    width: 540px;

    &__placeholder {
        position: relative;

        &-button-container {
            padding: 0.4em 0.5em;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &:has(.instagram__placeholder-image) {
            .instagram__placeholder-button-container {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                box-sizing: border-box;
            }
        }

        &-button {
            @include button();
            align-self: center;
            background-color: var(--primary-color);
        }
    }

    &__embed {
        opacity: 0;
        transition: opacity 0.2s;

        &:has(.instagram-media-rendered) {
            opacity: 1;
        }

        &:not(:first-child):has(.instagram-media-rendered) {
            margin-top: 1em;
        }
    }

    &__media {
        min-width: 280px !important;
        width: 540px !important;
        max-width: 100% !important;
        margin: 0 auto !important;
        border: 0;
        display: block;
        border: 0;
    }
}
