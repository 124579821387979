.tiktok {
    margin: 2em 0;

    &__placeholder {
        text-align: center;
        background-color: var(--primary-color);
        padding: 0.4em 0.5em;
    }

    &__embed {
        width: 325px !important;
        border-radius: 10px;
        margin: 0 auto !important;
    }
}
