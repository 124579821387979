.ce_segmented_text {
    text-transform: uppercase;
    font-family: var(--font-ruder);
    letter-spacing: 0.02em;
    margin: 1em 0;

    @include desktop() {
        h1 + & {
            margin-top: 0;
        }
    }

    @include larger() {
        h1 + & {
            margin-top: 0;
        }
    }

    & > span {
        & + span {
            margin-top: 1px;

            @include desktop() {
                margin-top: vwm(3px);
            }

            @include larger() {
                margin-top: vwmax(4px);
            }
        }
    }
}
