.newsitem--press {
    .newsitem {
        &__headline {
            font-size: rem(30px);
            margin-top: 0.4em;
            margin-bottom: -0.2em;
        }

        &__teaser {
            font-size: rem(20px);
        }
    }
}

.newsdetail--press {
    .newsdetail {
        &__headline {
            margin-bottom: 1em;
        }
    }
}
