.background-text {
    font-family: var(--font-ruder);
    font-variation-settings: "HGHT" 900;
    line-height: 1.22;
    text-transform: uppercase;
    letter-spacing: 0.07em;
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    position: fixed;
    left: 0;
    right: 0;
    top: calc(var(--container-padding-top) - #{vwmax(46px)});
    margin: auto;
    pointer-events: none;

    span ~ span {
        font-variation-settings: "HGHT" 100;
        line-height: 0.82;
    }
}
