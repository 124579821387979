.event-week {
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }

    @include tablet() {
        display: flex;
        align-items: flex-end;
        gap: 17px;
        margin-bottom: 10px;

        .eventlist--spielplan & {
            margin-bottom: 20px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__time {
        font-family: var(--font-delphia);
        font-size: 17px;
        font-weight: bold;
        font-stretch: expanded;
        font-style: normal;
        letter-spacing: -0.02em;
        text-transform: uppercase;
        margin: 0;
        margin-bottom: 4px;

        @include tablet() {
            font-size: 15px;
            margin-top: 0;
            min-width: 124px;
            margin-bottom: 2px;
        }

        @include larger() {
            font-size: 21px;
            min-width: 174px;
        }

        span.clock {
            font-size: 12px;
            font-weight: 500;
            font-stretch: normal;

            @include larger() {
                font-size: 15px;
            }
        }
    }

    &__location {
        font-size: 15px;
        font-family: var(--font-delphia);
        font-stretch: normal;
        font-weight: 500;
        letter-spacing: -0.02em;
        text-transform: uppercase;
        min-width: 66px;
        margin-bottom: 4px;

        @include tablet() {
            margin-bottom: 2px;
        }

        @include tablet('max') {
            display: none;
        }

        .eventlist--week & {
            display: none;
        }
    }

    &__title-container {
        .eventlist--spielplan & {
            @include tablet() {
                width: 40%;
                flex-shrink: 0;
            }
        }
    }

    &__title {
        margin: 0;
        text-transform: uppercase;
        font-family: var(--font-ruder);
        font-size: 38px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.1;
        letter-spacing: 0.05em;
        margin-top: -0.2em;
        display: inline-block;

        @include tablet() {
            font-size: 30px;
            margin-top: 0;
        }

        @include larger() {
            font-size: 38px;
        }
    }

    &__subtitle {
        font-family: var(--font-ruder);
        font-size: 25px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 0.1em;
        margin-top: -0.1em;
        margin-bottom: 0;
        text-transform: uppercase;
        display: inline-block;

        @include tablet() {
            font-size: 22px;
            margin-top: 0;
            line-height: 0.84;
        }

        @include larger() {
            font-size: 25px;
        }
    }

    &__contributor {
        font-family: var(--font-ruder);
        font-weight: 900;
        font-variation-settings: "HGHT" 250;
        font-size: 18px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        align-self: flex-end;
        margin-bottom: 2.5px;

        @include tablet('max') {
            display: none;
        }

        .eventlist--week & {
            display: none;
        }
    }

    &__min-age {
        display: none;
        flex-shrink: 0;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        background-color: var(--text-color);
        color: var(--primary-color);
        text-transform: uppercase;
        font-family: var(--font-ruder);
        font-size: 12px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        justify-content: center;
        align-items: center;
        text-align: center;
        letter-spacing: 0.1em;
        font-variation-settings: "HGHT" 100;
        margin-bottom: -0.3em;

        .eventlist--spielplan & {
            margin-left: auto;
        }

        @include tablet() {
            display: flex;
        }

        @include larger() {
            width: 55px;
            height: 55px;
            font-size: 18px;
            margin: -6px 0;
        }
    }

    &.hide-category .event-week__categories{
        display: none;
    }

    &.hide-age .event-week__min-age {
        display: none;
    }

    &__tickets-link {
        display: block;
        text-transform: uppercase;
        text-decoration: none;
        line-height: 25px;
        font-family: var(--font-ruder);
        font-size: 19px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 0.1em;
        padding: 2px;
        border: 2px solid var(--text-color);
        text-align: center;
        margin-top: 9px;
        padding-left: 32px;
        padding-right: 32px;
        transition: background-color var(--td), color var(--td);

        &:hover,
        &--unavailable {
            background-color: var(--text-color);
            color: var(--primary-color);
        }

        @include tablet() {
            margin-left: auto;
            margin-top: 0;
        }
    }
}

@include tablet() {
    .eventlist--spielplan {
        .event-week__min-age + .event-week__tickets-link {
            margin-left: 0;
        }
    }
}
