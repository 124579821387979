.contao-cookiebar {
    font-weight: normal;

    .cc-inner {
        font-size: 16px;
        line-height: 1.2em;
    }

    label {
        line-height: 1.2em;
    }

    .cc-cookies {
        & > p {
            font-size: 12px;
        }

        .cc-cookie {
            .cc-cookie-info {
                font-size: 12px;
            }
        }

        label.cookie {
            & + p {
                font-size: 12px;
            }
        }
    }

    .cc-info {
        & > p,
        & > a {
            font-size: 12px;
        }
    }
}

.cc-module,
.ccb-element-blocker .cc-btn {
    button {
        @include button();
    }
}
