nav.nav--main {
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    a {
        text-decoration: none;
    }
}

@include menu('max') {
    nav.nav--main {
        ul.level_1 {
            margin-left: 39px;
            margin-right: 39px;

            & > li {
                margin-top: 45px;

                & > a,
                & > span,
                & > strong {
                    display: block;
                    font-family: var(--font-delphia);
                    font-size: 18px;
                    font-weight: 500;
                    font-stretch: expanded;
                    font-style: normal;
                    letter-spacing: -0.02em;
                    text-transform: uppercase;
                    padding-left: 20px;
                }

                &.item--home {
                    display: none;
                }
            }
        }

        ul.level_2 {
            margin-top: 16px;

            & > li {
                &.submenu {
                    margin-top: 16px;

                    &:first-child {
                        margin-top: 0;
                    }
                }

                & > a,
                & > span,
                & > strong {
                    display: block;
                    font-family: var(--font-delphia);
                    font-size: 16px;
                    font-weight: 500;
                    font-stretch: expanded;
                    font-style: normal;
                    line-height: 1.63;
                    letter-spacing: -0.02em;
                }
            }
        }

        ul.level_3 {
            margin-left: 20.5px;
            margin-bottom: 16px;

            & > li {
                & > a,
                & > span,
                & > strong {
                    display: block;
                    font-family: var(--font-delphia);
                    font-size: 16px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.63;
                    letter-spacing: -0.02em;
                }
            }
        }
    }
}

@include menu() {
    nav.nav--main {
        display: block;
        margin-top: 66px;
        margin-left: 51px;
        margin-right: 51px;
        grid-column: 1 / -1;

        body.scroll--scrolled & {
            opacity: 1;
        }

        ul.level_1 {
            display: flex;
            justify-content: space-between;
            gap: 10px;

            & > li {
                justify-self: center;
                flex-grow: 1;

                &.item--mitspielen {
                    flex-grow: 0.6;
                }

                &.item--padagog-innen {
                    flex-grow: 1.2;
                }

                &.item--home {
                    opacity: 0;
                    visibility: hidden;
                    pointer-events: none;
                    width: 120px;
                    flex-grow: 0.8;
                }

                &.item--haus {
                    flex-grow: 0.6;
                }

                &.item--digitale-buhne {
                    flex-grow: 0.8;
                }

                & > a,
                & > span,
                & > strong {
                    font-family: var(--font-delphia);
                    font-size: 17px;
                    font-weight: 500;
                    font-stretch: expanded;
                    letter-spacing: -0.02em;
                    text-transform: uppercase;
                    display: block;
                    white-space: nowrap;
                    line-height: 1.37;
                }
            }
        }

        ul.level_2 {
            margin-top: 7px;
            margin-left: -10px;
            margin-right: -10px;

            & > li {
                &.submenu {
                    margin-top: 14px;

                    &:first-child {
                        margin-top: 0;
                    }
                }

                & > a,
                & > span,
                & > strong {
                    display: block;
                    font-family: var(--font-delphia);
                    font-size: 15px;
                    font-weight: 500;
                    font-stretch: expanded;
                    font-style: normal;
                    line-height: 1.33;
                    letter-spacing: -0.02em;
                }
            }
        }

        ul.level_3 {
            margin-top: 0;
            margin-bottom: 14px;
            margin-left: 15px;

            & > li {
                font-family: var(--font-delphia);
                font-size: 15px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.33;
                letter-spacing: -0.02em;
            }
        }
    }
}

@include larger() {
    nav.nav--main {
        ul.level_1 {
            grid-template-columns: repeat(3, 1fr) 1.0fr repeat(3, 1fr);

            & > li {
                & > a,
                & > span,
                & > strong {
                    font-size: 20px;
                }
            }
        }
    }
}

nav.nav--footer {
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li {
        & > a,
        & > span,
        & > strong {
            display: block;
        }
    }
}
