@import "~normalize.css/normalize.css";
@import "~tom-select/dist/css/tom-select.min.css";

/* global settings */
@import './settings/_images.scss'; @import './settings/_layout.scss';

/* functions and mixins */
@import './functions/_breakpoints.scss'; @import './functions/_grid.scss'; @import './functions/_mixins.scss'; @import './functions/_styles.scss';

/* base styles settings */
@import './base/_basic.scss'; @import './base/_colorways.scss'; @import './base/_fonts.scss'; @import './base/_screen.scss'; @import './base/_typography.scss';

/* styles of various components */
@import './components/_background-text.scss'; @import './components/_cookiebar.scss'; @import './components/_element-width.scss'; @import './components/_event-full.scss'; @import './components/_event-week.scss'; @import './components/_event.scss'; @import './components/_forms.scss'; @import './components/_google-translate-link.scss'; @import './components/_logo.scss'; @import './components/_menu-button.scss'; @import './components/_press.scss'; @import './components/_swiper.scss'; @import './components/_tinymce.scss';

/* section styles */
@import './sections/_container.scss'; @import './sections/_footer.scss'; @import './sections/_header.scss'; @import './sections/_main.scss'; @import './sections/_offcanvas.scss'; @import './sections/_wrapper.scss';

/* module styles */
@import './modules/_mod_article.scss'; @import './modules/_mod_eventfilter.scss'; @import './modules/_mod_eventlist.scss'; @import './modules/_mod_form.scss'; @import './modules/_mod_navigation.scss'; @import './modules/_mod_newslist.scss'; @import './modules/_mod_pageimage.scss'; @import './modules/_mod_search.scss';

/* element styles */
@import './elements/_ce_downloads.scss'; @import './elements/_ce_event_link.scss'; @import './elements/_ce_gallery.scss'; @import './elements/_ce_general_teaser.scss'; @import './elements/_ce_headline.scss'; @import './elements/_ce_hyperlink.scss'; @import './elements/_ce_image.scss'; @import './elements/_ce_instagram.scss'; @import './elements/_ce_segmented_text.scss'; @import './elements/_ce_text.scss'; @import './elements/_ce_tiktok.scss'; @import './elements/_ce_youtube.scss';
