.ce_text {
    &.width--950 {
        @include larger() {
            position: relative;
            left: vwmax(100px);

            .event-full__details & {
                left: 0;
            }
        }
    }

    &.text-element--infos {
        @include pr();
        font-family: var(--font-delphia);
        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.95;
        letter-spacing: 0.02em;
        margin: 2em 0;
        margin-left: vwmax(214px);

        & > h1,
        & > h2,
        & > h3,
        & > h4,
        & > h5,
        & > h6 {
            font-family: var(--font-delphia);
            font-size: rem(20px);
            font-weight: bold;
            font-stretch: expanded;
            font-style: normal;
            line-height: 0.95;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            margin-bottom: 0;
        }

        p:first-of-type {
            margin-top: 0;
        }

        p {
            margin-left: rem(21px);
        }
    }
}
