.ce_form {
    .formbody {
        position: relative;
    }

    /* General */
    [type="submit"] {
        @include button();
    }

    label {
        display: block;
        font-size: 16px;
        font-stretch: expanded;
        letter-spacing: -0.02em;
        margin-bottom: 0.43em;
        font-weight: 500;

        @include larger() {
            font-size: 18px;
        }
    }

    p.error {
        color: red;
        font-size: 15px;
        letter-spacing: -0.02em;
        margin: 0;
        margin-top: 0.4375em;
    }

    input:not([type="radio"]):not([type="checkbox"]),
    select,
    textarea,
    .ts-control {
        border: 2px solid var(--text-color);
        background-color: transparent;
        outline: 0;
        border-radius: 0;
        padding: 6.5px 0.2em;
        font-size: 19px;
        line-height: 25px;
        display: block;
        width: 100%;
        box-sizing: border-box;
        box-sizing: border-box;
        height: 42px;
    }

    textarea {
        height: auto;
    }

    select,
    .ts-control {
        text-align: center;
        padding-top: 7px;
        padding-bottom: 6px;
        font-family: var(--font-ruder);
        font-weight: 900;
        font-size: 19px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        font-stretch: normal;
        line-height: 25px;
        appearance: none;
        background-image: url('../../images/arrow-down.svg');
        background-position: right 10px center;
        background-repeat: no-repeat;
        padding-right: 22px;

        @include larger() {
            font-size: 21px;
        }

        option {
            text-align: left;
            font-family: var(--font-delphia);
            font-stretch: expanded;
            font-size: 16px;
            font-weight: 400;
            text-transform: none;
            letter-spacing: -0.02em;
        }
    }

    .ts-control {
        &:not(.rtl) {
            padding-right: 22px !important;
        }
    }

    .ts-wrapper.single.input-active .ts-control {
        background-color: transparent;
        background-image: url('../../images/arrow-down.svg');
        background-position: right 10px center;
        background-repeat: no-repeat;
    }

    .field--indent {
        margin-left: 33px;
        margin-top: -15px;
    }

    /* Fieldset */
    fieldset {
        margin: 0;
        display: block;
        padding: 0;
        border: 0;
        outline: 0;
        border-radius: 0;
    }

    .formbody > fieldset {
        margin-bottom: 23px;
    }

    /* Radio & Checkbox */
    .checkbox_container,
    .radio_container {
        border: 0;
        margin: 0;
        padding: 0;
        outline: 0;
        display: flex;
        flex-direction: column;
        row-gap: 11px;
        column-gap: 53px;

        &.field--horizontal {
            flex-direction: row;
            flex-wrap: wrap;
        }

        & > span {
            display: block;
            position: relative;
        }

        input {
            opacity: 0;
            pointer-events: none;
            position: absolute;
            left: 3px;
            top: 8px;
        }

        label {
            display: block;
            align-items: center;
            position: relative;
            padding-left: 32px;
            margin-bottom: 0;

            &:before {
                width: 20px;
                height: 20px;
                box-sizing: border-box;
                content: '';
                display: block;
                background-color: transparent;
                border: 2px solid var(--text-color);
                cursor: pointer;
                position: relative;
                flex-shrink: 0;
                position: absolute;
                left: 0;
                top: -2px;

                @include larger() {
                    top: 0;
                }
            }

            &:after {
                position: absolute;
                width: 12px;
                height: 12px;
                content: '';
                display: block;
                background-color: var(--text-color);
                opacity: 0;
                transition: 0.1s;
                left: 4px;
                pointer-events: none;
                top: 2px;

                @include larger() {
                    top: 4px;
                }
            }

            &:active &:after {
                opacity: 0.5;
            }
        }

        input:checked ~ label {
            &:before {
                border-color: var(--text-color);
            }

            &:after {
                opacity: 1;
                background-color: var(--text-color);
            }
        }
    }

    .radio_container {
        label {
            &:before,
            &:after {
                border-radius: 50%;
            }
        }
    }

    /* Widgets */
    .widget {
        display: flex;
        flex-direction: column;

        p.error {
            order: 99;
        }

        &-select {
            &.widget--time,
            &.widget--alternative {
                select {
                    font-family: var(--font-delphia);
                    font-stretch: expanded;
                    font-weight: 500;
                    font-size: 15px;
                    letter-spacing: -0.02em;
                    text-transform: uppercase;
                }
            }
        }
    }

    /* Grid Layout */
    .formbody,
    .formbody > fieldset,
    .formbody > .fields,
    .formbody > .fields > fieldset {
        display: grid;
        grid-template-columns: 1fr;
        column-gap: 12px;
        row-gap: 26px;
    }

    @include tablet() {
        .formbody,
        .formbody > fieldset,
        .formbody > .fields,
        .formbody > .fields > fieldset {
            grid-template-columns: repeat(12, 1fr);
            column-gap: 12px;
            row-gap: 26px;
        }

        .formbody > fieldset,
        .formbody > .fields,
        .formbody > fieldset > fieldset,
        .formbody > .fields > fieldset > fieldset {
            grid-column: span 12;
        }

        .widget {
            grid-column: span 12;
            min-width: 0;

            @include grid-columns(12, 'field-span');

            & > input {
                display: block;
                max-width: 100%;
                box-sizing: border-box;
            }
        }
    }
}
