.ce_youtube {
    margin-left: calc(var(--outer-padding) * -1);
    margin-right: calc(var(--outer-padding) * -1);

    @include tablet() {
        margin-left: 0;
        margin-right: 0;
    }

    .video_container {
        img {
            width: 100%;
            height: auto;
        }
    }

    &.video--fullscreen {
        margin: 0;
        top: calc(var(--container-padding-top) * -1);
        width: 100vw;
        height: 100vh;
        position: relative;
        left: -50vw;
        margin-left: 50%;

        .video_container {
            padding: 0;
            height: 100%;
        }

        .ce_hyperlink.hyperlink--back + & {
            top: calc((var(--container-padding-top) * -1) - 25px);
        }
    }
}
