.google-translate-link {
    font-family: var(--font-delphia);
    font-size: 15px;
    font-weight: 500;
    font-stretch: expanded;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.02em;
    margin: 1em 40px;

    @include menu() {
        margin-top: 0;
        margin-bottom: 36px;
    }

    a {
        text-decoration: none;
    }
}
