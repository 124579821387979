.form--newsletter--footer {
    .formbody {
        position: relative;
    }

    input[type="email"] {
        border: 0;
        border-bottom: 2px solid var(--text-color);
        padding-left: 9px;
        padding-right: 20px;
        background-color: transparent;
        outline: 0;
        font-size: 13px;
        font-family: var(--font-delphia);
        font-stretch: normal;
        letter-spacing: -0.02em;
        line-height: 20px;
        padding-top: 3.5px;
        padding-bottom: 0.5px;
        width: calc(100% - 29px);

        @include tablet() {
            padding-right: 11px;
            width: calc(100% - 20px);
        }

        @include larger() {
            font-size: 15px;
            padding-top: 4.5px;
            padding-bottom: 1.5px;
        }

        &::placeholder {
           opacity: 1;
           color: inherit; 
        }
    }

    [type="submit"] {
        @include sprite('../../images/arrow-right-small.svg');
        background-size: 9.648px auto;
        outline: 0;
        border: 0;
        padding: 0;
        margin: 0;
        background-color: transparent;
        width: 11px;
        height: 26px;
        position: absolute;
        right: 9px;
        top: 0;
        text-indent: 20px;
        white-space: nowrap;
        overflow: hidden;
        cursor: pointer;

        @include tablet() {
            right: 0;
        }
    }
}
