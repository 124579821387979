.event-teaser {
    $root: &;
    background-color: var(--primary-color);
    position: relative;

    .eventlist--featured & {
        @include tablet() {
            display: grid;
            grid-template-columns: vwm(835px) 1fr;
        }
    
        @include larger() {
            grid-template-columns: vwmax(1377px) 1fr;
        }
    }

    &__image {
        .image-wrapper {
            overflow: hidden;
        }

        img {
            transition: transform var(--td);
            width: 100%;
        }
    }

    &__content {
        min-width: 0;
        padding: 10px;
        padding-top: 8px;

        @include tablet() {
            padding-top: vwm(8px);
            padding-right: vwm(14px);
            padding-left: vwm(14px);
            padding-bottom: vwm(15px);
            
            .eventlist--featured & {
                position: relative;
            }
        }

        @include larger() {
            padding-top: vwmax(8px);
            padding-right: vwmax(15px);
            padding-left: vwmax(15px);
            padding-bottom: vwmax(17px);
        }
    }

    &__min-age {
        width: 49.5px;
        height: 49.5px;
        background-color: var(--primary-color);
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        font-size: 16px;
        font-family: var(--font-ruder);
        font-weight: normal;
        font-stretch: normal;
        line-height: 1.31;
        letter-spacing: 0.1em;
        position: absolute;
        top: -25px;
        right: 25.6px;

        @include tablet() {
            font-size: vwm(21px);
            width: vwm(65px);
            height: vwm(65px);
            top: vwm(-32.5px);
            right: vwm(20.3px);

            .eventlist--featured & {
                right: auto;
                left: vwm(-100px);
            }
        }

        @include larger() {
            font-size: vwmax(21px);
            width: vwmax(65px);
            height: vwmax(65px);
            top: vwmax(-32.5px);
            right: vwm(32.3px);

            .eventlist--featured & {
                right: auto;
                left: vwmax(-107px);
            }
        }
    }

    &.hide-category .event-teaser__category{
        display: none;
    }

    &.hide-age .event-teaser__min-age {
        display: none;
    }

    &__info {
        font-family: var(--font-ruder);
        font-stretch: normal;
        font-size: 14px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        display: flex;
        justify-content: space-between;
        font-weight: 900;
        gap: 1em;

        @include tablet() {
            font-size: vwm(18px);
        }

        @include larger() {
            font-size: vwmax(18px);
        }
    }

    &__contributor {
        text-align: right;
    }

    &__title {
        font-family: var(--font-ruder);
        font-stretch: normal;
        font-weight: 900;
        letter-spacing: 0.025em;
        text-transform: uppercase;
        margin: 0;
        transition: font-variation-settings var(--td), line-height var(--td);
        font-variation-settings: "HGHT" 250;
        line-height: 0.85;
    }

    @include tablet() {
        &:not(.event-teaser--screen):hover {
            #{$root} {
                &__title.scale-text {
                    font-variation-settings: "HGHT" 600;
                    line-height: 1.08;
                }
            }
        }
    }

    &__title {
        span.scale-text {
            transition: font-variation-settings var(--td), line-height var(--td);
            font-variation-settings: "HGHT" 200;
            line-height: 0.83;

            &:first-child {
                font-variation-settings: "HGHT" 700;
                line-height: 1.08;
            }
        }
    }

    @include tablet() {
        &:not(.event-teaser--screen):hover {
            #{$root} {
                &__title {
                    span.scale-text {
                        font-variation-settings: "HGHT" 700;
                        line-height: 1.07;

                        &:first-child {
                            font-variation-settings: "HGHT" 200;
                            line-height: 0.82;
                        }
                    }
                }
            }
        }
    }

    &__subtitle {
        font-family: var(--font-ruder);
        font-stretch: normal;
        letter-spacing: 0.025em;
        text-transform: uppercase;
        font-weight: 900;
        line-height: 0.945;
        font-variant-ligatures: none;
        margin: 0.1em 0;

        & + #{$root}__teaser {
            margin-top: 0.3em;
        }
    }

    &__categories {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        row-gap: 1em;
        column-gap: 0.5em;
        font-family: var(--font-ruder);
        font-stretch: normal;
        font-size: 19px;
        font-weight: 900;
        line-height: 1.5;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        font-variation-settings: "HGHT" 900;

        @include tablet() {
            font-size: vwm(21px);
        }

        @include larger() {
            font-size: vwmax(21px);
        }

        &--c1 {
            justify-content: center;
        }
    }

    &__time {
        font-family: var(--font-delphia);
        font-size: 31px;
        font-weight: bold;
        font-stretch: expanded;
        font-style: normal;
        letter-spacing: -0.02em;
        text-transform: uppercase;
        text-align: center;
        padding: 0;
        margin: 0;
        padding-top: 0.185em;
        padding-bottom: 0.075em;
        line-height: 0.82;
        transition: font-size var(--td), transform var(--td), margin var(--td);
        display: block;

        &-entry {
            display: block;
        }

        @include tablet() {
            font-size: vwm(52px);
        }

        @include larger() {
            font-size: vwmax(52px);
        }

        .eventlist--featured & {
            font-size: 27px;

            @include tablet() {
                font-size: vwm(41px)
            }
    
            @include larger() {
                font-size: vwmax(52px);
            }
        }
    }

    &:not(.event-teaser--screen):hover {
        #{$root} {
            &__time {
                @include tablet() {
                    transform: scale(0.75);
                }
            }

            &__image {
                img {
                    transform: scale(1.065);
                }
            }
        }
    }

    &__teaser {
        @include pr();
        text-align: center;
        font-size: 14.3px;
        font-weight: 500;
        line-height: 1.06;
        font-stretch: normal;
        letter-spacing: 0.02em;
        margin: 0;

        @include tablet() {
            font-size: vwm(18px);
        }

        @include larger() {
            font-size: vwmax(18px);
        }
    }

    &__dates {
        list-style: none;
        padding: 0;
        margin: 0;
        margin-top: 5px;

        @include tablet() {
            margin-top: vwm(13px);
        }

        @include larger() {
            margin-top: vwmax(13px);
        }

        &-item {
            &-link {
                display: flex;
                gap: 0.5em;
                justify-content: space-between;
                align-items: center;
                border: 2px solid var(--text-color);
                line-height: 25px;
                padding: 6.5px 16px 6.5px 11px;
                text-decoration: none;
                text-transform: uppercase;
                transition: background-color var(--td), color var(--td);
                
                &:hover,
                &--unavailable {
                    background-color: var(--text-color);
                    color: var(--primary-color);
                }

                &:hover {
                    #{$root} {
                        &__dates-item-date {
                            font-size: 17px;
                        }

                        &__dates-item-weekday {
                            opacity: 0;
                        }
                    }
                }

                @include tablet() {
                    line-height: vwm(27px);
                    padding: vwm(5.5px) vwm(16px) vwm(5.5px) vwm(11px);
                }

                @include larger() {
                    line-height: vwmax(27px);
                    padding: vwmax(5.5px) vwmax(16px) vwmax(5.5px) vwmax(11px);
                }
            }

            &-label {
                font-family: var(--font-ruder);
                font-weight: 900;
                font-stretch: normal;
                font-size: 19px;
                letter-spacing: 0.1em;

                @include tablet() {
                    font-size: vwm(21px);
                }

                @include larger() {
                    font-size: vwmax(21px);
                }
            }

            &-date {
                font-family: var(--font-delphia);
                font-stretch: expanded;
                font-size: 14px;
                font-weight: bold;
                letter-spacing: -0.02em;
                line-height: 1.065;
                transition: font-size var(--td);

                @include tablet() {
                    font-size: vwm(15px);
                }

                @include larger() {
                    font-size: vwmax(15px);
                }
            }

            &-clock,
            &-weekday {
                font-size: 11px;
                transition: opacity var(--td);

                @include tablet() {
                    font-size: vwm(11px);
                }

                @include larger() {
                    font-size: vwmax(11px);
                }
            }

            & + & {
                margin-top: 5px;
            }
        }
    }

    .eventlist--more & {
        @include larger() {
            #{$root} {
                &__content {
                    padding-left: vwmax(9.5px);
                    padding-right: vwmax(9.5px);
                }

                &__info {
                    font-size: vwmax(13px);
                }

                &__time {
                    font-size: vwmax(38px);
                }

                &__categories {
                    font-size: vwmax(15px);
                }

                &__dates {
                    &-item {
                        &-label {
                            font-size: vwmax(15px);
                        }

                        &-weekday {
                            display: none;
                        }

                        &-link {
                            padding-top: vwmax(2px);
                            padding-bottom: vwmax(2px);
                        }
                    }
                }
            }
        }
    }

    &--screen {
        margin: 0 !important;

        figcaption {
            padding-left: 0.3em;
        }

        .swiper-wrapper & {
            transition: opacity 0.4s;

            &.swiper-slide-active ~ .event-teaser {
                opacity: 0;
            }
        }
    }
}

.mod_eventlist:not(.eventlist--featured) .event-teaser:not(.event-teaser--source--default) {
    display: flex;
    flex-direction: column;

    .event-teaser {
        &__image {
            order: 99;
        }

        &__content {
            padding-top: 0;

            @include tablet() {
                padding-bottom: vwm(8px);
                padding-top: 0;
            }
    
            @include larger() {
                padding-bottom: vwmax(8px);
                padding-top: 0;
            }
        }
    } 
}
