:root {
    --swiper-pagination-color: var(--text-color);
}

.swiper {
    &-pagination {
        &-horizontal {
            display: flex;
            justify-content: center;
        }

        &-bullet {
            display: block;
        }
    }
}
