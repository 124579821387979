.mod_search.search--header {
    margin: 0 40px;
    margin-top: 1em;
    font-family: var(--font-delphia);
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.02em;
    display: flex;
    align-items: center;
    gap: 7.5px;

    @include menu() {
        justify-self: flex-end;
        margin-top: 0;
        margin-bottom: 36px;
    }

    .search__label {
        font-family: var(--font-delphia);
        font-size: 18px;
        font-weight: 500;
        font-stretch: expanded;
        font-style: normal;
        letter-spacing: -0.02em;
        text-transform: uppercase;

        @include menu() {
            font-size: 20px;
        }
    }

    .widget-submit {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    form {
        display: block;
        flex-grow: 1;
    }

    input {
        background-color: transparent;
        border: 0;
        outline: 0;
        border-radius: 0;
        border-bottom: 2px solid var(--text-color);
        display: block;
        width: 100%;
        padding: 0;
        position: relative;
        height: 22px;

        @include menu() {
            height: 24px;
            width: 324px;
        }
    }
}

.mod_search.search--results {
    .formbody {
        position: relative;
        max-width: 360px;
    }

    input[type="search"] {
        background-color: transparent;
        border: 0;
        border-bottom: 2px solid var(--text-color);
        padding: 0;
        padding-left: 0.2em;
        padding-right: 1em;
        outline: 0;
        margin: 0;
        width: 100%;
        box-sizing: border-box;
    }

    [type="submit"] {
        @include sprite('../../images/arrow-right-small.svg');
        background-size: 9.648px auto;
        outline: 0;
        border: 0;
        padding: 0;
        margin: 0;
        background-color: transparent;
        width: 11px;
        height: 26px;
        position: absolute;
        right: 9px;
        top: 0;
        bottom: 0;
        margin: auto;
        text-indent: 20px;
        white-space: nowrap;
        overflow: hidden;
        cursor: pointer;

        @include tablet() {
            right: 0;
        }
    }
}
