.mod_eventfilter {
    margin-top: 20px;
    margin-bottom: 25px;
    position: relative;
    z-index: 1;

    @include tablet() {
        display: flex;
        align-items: center;
        margin-top: 32px;
        margin-bottom: 37px;
    }

    & > h1,
    & > h2,
    & > h3,
    & > h4,
    & > h5,
    & > h6 {
        display: none;
        font-family: var(--font-ruder);
        font-weight: 900;
        font-variation-settings: "HGHT" 100;
        font-size: 51px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        margin: 0;
        line-height: 0.9;
        margin-right: 10px;

        @include tablet() {
            display: block;
        }
    }

    form {
        display: flex;
        flex-wrap: wrap;
        gap: 11px;
    }

    select,
    .ts-control {
        background-color: transparent;
        border: 2px solid var(--text-color);
        border-radius: 0;
        outline: 0;
        font-family: var(--font-ruder);
        letter-spacing: 0.1em;
        font-weight: 900;
        text-transform: uppercase;
        text-align: center;
        text-align-last: center;
        font-size: 21px;
        padding: 0;
        width: 100%;
        height: 34px;
        box-sizing: border-box;
        appearance: none;
        background-image: url('../../images/arrow-down.svg');
        background-position: right 10px center;
        background-repeat: no-repeat;
        color: inherit;

        @include tablet() {
            background-position: right 48px center;
            text-align: center;
            width: 200px;
            height: 42px;

            &[name="category_2"],
            &[name="weekday"] {
                background-position: right 10px center;
            }
        }
    }

    .ts-wrapper {
        width: 100%;

        @include tablet() {
            width: 200px;
        }
    }

    .ts-control {
        background: none;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-wrap: nowrap;
        gap: 10px;

        .item {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 1.25;
        }

        &:before {
            content: '';
            display: block;
            width: 9.783px;  
            flex-shrink: 0;
            flex-grow: 1;
        }

        &:after {
            @include sprite('../../images/arrow-down.svg');
            content: '';
            background-position: left center;
            display: block;
            width: 9.783px;
            height: 9.648px;
            flex-shrink: 0;
            flex-grow: 1;
        }

        &:not(.rtl) {
            padding-right: 2px !important;
        }
    }

    .ts-wrapper.single.input-active .ts-control {
        background: none;
    }

    option {
        text-transform: none;
        line-height: normal;
        font-size: 20px;
        text-align: left;
        font-family: var(--font-delphia);
        font-weight: normal;
        padding: 0;
    }
}
