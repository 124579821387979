#logo {
    z-index: 20;
    position: relative;
    margin-top: 11px;
    transition: width var(--td), transform var(--td), top var(--td);
    width: 198.7px;
    margin-left: auto;
    margin-right: auto;

    body.scroll--scrolled &,
    body.pull-down-menu &,
    body:not(.page--index) & {
        width: 130.1px;
    }

    @include menu('max') {
        body.show-menu & {
            width: 130.1px;
        }
    }

    @include menu() {
        width: 246.3px;
        position: absolute;
        left: 0;
        right: 0;
        top: 4.7px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 0;

        body.scroll--scrolled &,
        body.pull-down-menu &,
        body:not(.page--index) &,
        body.show-menu & {
            top: 25px;
            width: 154.7px;
        }
    }
    
    @include larger() {
        width: 391.1px;
        top: 9.7px;

        body.scroll--scrolled &,
        body.pull-down-menu &,
        body:not(.page--index) &,
        body.show-menu & {
            top: 25.7px;
            width: 209.3px;
        }
    }
}
