.mod_article.article--teaser {
    @include tablet() {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-auto-flow: dense;
        column-gap: vwm(20px);
        margin-left: calc(71px - var(--outer-padding));
        margin-right: calc(71px - var(--outer-padding));
        padding-top: vwm(270px);
        padding-bottom: vwm(270px);

        & > * {
            margin-top: 0;
            margin-bottom: 0;
            align-self: flex-start;

            &:nth-child(odd) {
                grid-column: 2 / 3;
                margin-top: -20%;
            }

            &:nth-child(even) {
                grid-column: 1 / 2;
                margin-top: 120%;
            }

            &:first-child {
                margin-top: vwm(100px);
            }

            &:last-child {
                margin-bottom: vwm(100px);
            }
        }
    }

    @include larger() {
        column-gap: vwmax(174px);
        margin-left: calc(164px - var(--outer-padding));
        margin-right: calc(164px - var(--outer-padding));
        padding-top: vwmax(390px);
        padding-bottom: vwmax(390px);

        & > * {
            &:nth-child(odd) {
                margin-left: vwmax(-190px);
            }

            &:first-child,
            &:nth-child(5),
            &:nth-child(9) {
                margin-left: 0;
            }
        }
    }

    body.page--index & {
        box-sizing: border-box;
    }
}

.mod_article.article--this-week {
    @include fwbg(var(--primary-color));
    padding-top: 1px;

    &:after {
        content: '';
        display: table;
    }
}

.mod_article.width--1500 {
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
}

.mod_article.article-layout--teaser,
.page-teasers__teasers,
.mod_event_teaser .eventlist__items {
    @include tablet() {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 20px;
        row-gap: 40px;
    }

    @include larger() {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

.mod_article.article-layout--form {
    @include tablet() {
        margin-left: calc(26px - var(--outer-padding));
        margin-right: calc(26px - var(--outer-padding));
        display: grid;
        grid-template-columns: 279px 1fr;
        column-gap: 1em;
    }

    @include larger() {
        margin-left: calc(74px - var(--outer-padding));
        margin-right: calc(74px - var(--outer-padding));
        max-width: 1303px;
        grid-template-columns: 352px 1fr;
    }
}

.mod_article.article--fixed {
    &.apply-fixed {
        position: fixed;
    }

    & ~ .mod_article {
        position: relative;
        background-color: var(--primary-color);
        z-index: 1;
    }
}

.mod_article.article--digital-stage-3d {
    $side: 1100px;
    $height: $side * 0.5625;
    --perspective: #{vwmax(2400px)};

    --td: 0.4s;
    position: relative;
    width: vwmax($side);
    height: vwmax($height);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: vwmax($height * 0.5);
    transform: scale(1.2);

    @include tablet() {
        transform: none;
    }

    & > [class^="ce_"] {
        width: vwmax($side);
        height: vwmax($height);
        margin: 0;
        background-color: var(--text-color);
        color: var(--primary-color);
        position: absolute;
        left: 0;
        top: 0;
        transform-origin: 50% 50% vwmax($side * 0.5);
        transition: transform var(--td), opacity var(--td), height var(--td), top var(--td), left var(--td), width var(--td);
        transition-timing-function: ease-out;

        // left side
        &:nth-child(1) {
            transform: perspective(var(--perspective)) translateX(vwmax(-10px)) rotateY(45deg);

            &:hover {
                transform: perspective(var(--perspective)) translateX(vwmax(-10px)) rotateY(45deg) scale(1.05);
            }
        }

        // right side (back side)
        &:nth-child(2) {
            transform: perspective(var(--perspective)) translateX(vwmax(10px)) rotateY(-45deg);

            &:hover {
                transform: perspective(var(--perspective)) translateX(vwmax(10px)) rotateY(-45deg) scale(1.05);
            }
        }

        // bottom side
        &:nth-child(3) {
            width: vwmax($side);
            height: vwmax($side);
            position: relative;
            top: vwmax(($side - $height) * -0.5);
            transform: perspective(var(--perspective)) translateY(vwmax(($side - $height) * -0.5 + 20px)) rotateY(-45deg) rotateX(90deg);

            &:hover {
                transform: perspective(var(--perspective)) translateY(vwmax(($side - $height) * -0.5 + 20px)) rotateY(-45deg) rotateX(90deg) scale(1.05);
            }

            & + * {
                display: none;
            }
        }
    }

    &[data-clicked-stage] {
        & > [class^="ce_"] {
            opacity: 0;
        }
    }

    &[data-clicked-stage="1"] {
        & > [class^="ce_"]:nth-child(1) {
            z-index: 1;
            opacity: 1;
            transform: perspective(var(--perspective)) translateX(0) translateY(0) translateZ(vwmax($side * 0.75)) rotateY(0);
        }
    }

    &[data-clicked-stage="2"] {
        & > [class^="ce_"]:nth-child(2) {
            z-index: 1;
            opacity: 1;
            transform: perspective(var(--perspective)) translateX(0) translateY(0) translateZ(vwmax($side * 0.75)) rotateY(0);
        }
    }

    &[data-clicked-stage="3"] {
        & > [class^="ce_"]:nth-child(3) {
            z-index: 1;
            opacity: 1;
            height: vwmax($side * 0.5625);
            top: vwmax(($height - $side * 0.5625) * 0.5);
            transform: perspective(var(--perspective)) translateY(vwmax($height * 0.15)) translateZ(vwmax($side * 0.75)) rotateY(0) rotateX(0);
        }
    }

    & > .ce_image {
        figure, img, .image-wrapper {
            width: 100%;
            height: 100%;
            object-fit: fill;
        }
    }

    & > .ce_player {
        .video_container, video {
            width: 100%;
            height: 100%;
            object-fit: fill;
        }
    }

    & > .ce_headline,
    & > .ce_hyperlink {
        padding: 0 vwmax(15px);
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    & > .ce_hyperlink {
        overflow: hidden;
        padding: 0;
        font-family: var(--font-ruder);
        font-variation-settings: "HGHT" 640;
        line-height: 1.04;
        font-weight: 900;
        text-transform: uppercase;
        letter-spacing: 0.25em;

        a {
            padding: 0 vwmax(15px);
            display: block;
            flex-grow: 1;
            text-decoration: none;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
}

.mod_article.article-layout--screen {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    /* visual offset due to captions */
    padding-top: 18px;
}

.mod_article.article--digital-stage-margin {
    margin-top: vwmax(1120px);
}

.mod_article.article--background {
    @include fwbg(var(--primary-color));
    padding: 1px 0;

    &:first-child:before {
        top: calc(var(--container-padding-top) * -1);
    }
}
